<section class="hero is-fullheight-with-navbar is-primary is-bold">
  <div class="hero-body">
    <div class="container">
      <h1 class="title">
        Note Keeper
      </h1>
      <h2 class="subtitle">
        A Place to store your notes!
      </h2>
    </div>
  </div>
</section>
