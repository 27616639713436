<div class="section">
  <h3 class="subtitle">
      Reset Password
  </h3>
  <div class="field">
  <p class="control has-icons-left has-icons-right">
    <input [(ngModel)]="password" class="input" type="password" placeholder="Password" (keyup)="validateForm()" [ngClass]="validatePassword">
    <span class="icon is-small is-left">
      <fa-icon [icon]="faLock"></fa-icon>
    </span>
  </p>
</div>
<div class="field">
  <p class="control has-icons-left">
    <input [(ngModel)]="confirmPassword" class="input" type="password" placeholder="Confirm Password" (keyup)="validateForm()" [ngClass]="validateConfirmPassword">
    <span class="icon is-small is-left">
      <fa-icon [icon]="faLock" primaryColor="red"></fa-icon>
    </span>
  </p>
</div>
<div class="field">
  <p class="control">
    <button class="button is-success mr-1" (click)="submitResetPw()" [disabled]="disableSubmit">
      Reset Password
    </button>
  </p>
</div>
</div>

<div class="modal" [ngClass]="isActive">
  <div class="modal-background" (click)="closeModal()"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{modalTitle}}</p>
      <button class="delete" aria-label="close" (click)="closeModal()"></button>
    </header>
    <section class="modal-card-body">
      {{modalBody}}
    </section>
  </div>
</div>
