<div class="section">
  <h3 class="subtitle">
      Change Password
  </h3>
  <div class="field">
  <p class="control">
    <input class="input" [(ngModel)]="oldPassword" type="password" placeholder="Old Password" (keyup)="validateForm('old')" [ngClass]="validateOldPw" [disabled]="disableForm">
  </p>
</div>
<div class="field">
  <p class="control">
    <input class="input" [(ngModel)]="newPassword" type="password" placeholder="New Password" (keyup)="validateForm('new')" [ngClass]="validateNewPw" [disabled]="disableForm">
  </p>
</div>
<div class="field">
  <p class="control">
    <input class="input" [(ngModel)]="confirmNewPassword" type="password" placeholder="Confirm New Password" (keyup)="validateForm('confirm')" [ngClass]="validateConfirmNewPw" [disabled]="disableForm">
  </p>
</div>
<div class="field">
  <p class="control buttons">
    <button class="button is-primary" (click)="changePassword()" [ngClass]="btnLoader" [disabled]="disableSubmit">
      Change Password
    </button>
    <button class="button is-danger" (click)="deleteUser()">Delete Account</button>
  </p>
</div>
</div>

<div class="modal" [ngClass]="confirmModal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Confirm Delete</p>
    <!--  <button class="delete" aria-label="close"></button> -->
    </header>
    <section class="modal-card-body">
      Are You Sure?
    </section>
    <footer class="modal-card-foot">
      <button class="button is-danger" (click)="confirmDeleteUser()">Confirm Delete</button>
      <button class="button" (click)="closeModal()">Cancel</button>
    </footer>
  </div>
</div>

<div class="modal" [ngClass]="notifyModal">
  <div class="modal-background" (click)="closeModal()"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{notifyTitle}}</p>
      <button class="delete" aria-label="close" (click)="closeModal()"></button>
    </header>
    <section class="modal-card-body">
      {{notifyBody}}
    </section>
  </div>
</div>
