<footer class="footer hero-foot">
    <div class="container">
    <div class="columns">
      <div class="column is-one-quarter has-text-left"></div>
      <div class="column is-half has-text-centered">
        &copy; 2019. <a href="https://bithatchery.com" target="_blank">Tone T. Thangsongcharoen.</a>
      </div>
      <div class="column is-one-quarter has-text-right">
        <span>
          <a href="https://github.com/thang2162/notekeeper" style="color: hsl(0, 0%, 29%);" target="_blank">
            <fa-icon [icon]="faGithubSquare" size="2x"></fa-icon>
          </a>
        </span>&nbsp;
        <span>
          <a href="https://www.linkedin.com/in/tone-thangsongcharoen-52bb7266/" style="color: hsl(0, 0%, 29%);" target="_blank">
            <fa-icon [icon]="faLinkedin" size="2x"></fa-icon>
          </a>
        </span>
      </div>
    </div>
    </div>
    </footer>
